import React from "react";
import {toLabel} from "./utils";

import "c3/c3.css";
import ValueFormatter from "../service/value-formatter";
import {Chart} from "./chart";

const SUM = "Summe";

export const GraphViewer = ({data, grouping, labels, columns, interval, showSum}) => {

    labels = grouping.length ? labels[grouping[0]] : [];

    let dateFormat = "%d.%m.%Y";

    if (-1 < interval.indexOf('y')) {
        dateFormat = '%Y';
    } else if (-1 < interval.indexOf('M')) {
        dateFormat = '%m/%Y';
    } else if (-1 < interval.indexOf('w')) {
        dateFormat = '%W';
    }

    const graphData = Object.entries(data)
        .filter(([k]) => "__sums__" !== k || showSum)
        .map(([k, entry]) => [
            "__sums__" === k ? SUM : toLabel(k, labels),
            entry instanceof Array ? entry : entry.__sums__
        ])
        .sort(([a], [b]) =>
            SUM === a ? -1 :
                SUM === b ? 1 :
                    a.localeCompare(b)
        );

    // Determine data that could be included in same graph

    const graphs = new Map;

    columns.forEach(({label, format, aggregation}, index) => {
        // Map can use arrays directly, but equality differs
        const key = JSON.stringify([label.split(',')[0], format, aggregation]);

        if (!graphs.has(key)) {
            graphs.set(key, []);
        }
        graphs.get(key).push(index);
    });

    const columnData = [['x']];

    graphData.forEach((data)=> {
        columnData.push(data.flat());
    })

    Array.from(graphs.entries()).map(([key, indexes]) => {
        indexes.forEach(i => columnData[0].push(columns[i].date));
    })

    console.log("data", columnData);

    return (
        <Graph
            columns={columnData}
            formatter={columns[0].format}
            format={dateFormat}
        />
    );
}

export const Graph = ({label, columns, format, formatter}) => (
    <Chart config={{
        padding: {
            right: 30
        },
        data: {
            x: 'x',
            xFormat: '%Y-%m-%dT%H:%M:%S.%L%Z',
            columns,
        },
        axis: {
            x: {
                type: 'timeseries',
                tick: {format}
            },
            y: {
                tick: {
                    format: value => ValueFormatter.format(formatter, value)
                }
            }
        },
        title: {
            text: label
        },
        tooltip: {
            tick: {
                format: value => ValueFormatter.format(formatter, value)
            }
        }
    }}/>
);

export default GraphViewer;

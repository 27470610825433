import {bindable, customElement, inject} from 'aurelia-framework';
import {EventAggregator} from "aurelia-event-aggregator";
import {Client} from "../api/client";
import getPropertyByPath from "../utilities/get-property-by-path";

@customElement('sio-settings')
@inject(Client, EventAggregator)
export class Settings {

    @bindable object;

    loading = true;

    constructor(client, ea) {
        this.client = client;
        this.ea = ea;
    }

    async bind() {
        this.loading = true;

        const config = await this.client.get(this.object.modelId + '/' + this.object.id + '/settings');
        const settings = this.object.settings || {modelId: this.object.modelId + '-settings'};

        this.panels = (config.panels || []).sort((a, b) => b.priority - a.priority);

        const usedTabs = [];

        this.panels.forEach(panel => {
            panel.object = getPropertyByPath(settings, panel.options.settings.property) || {modelId: panel.options.modelId};
            panel.object.parent = this.object;
            panel.actionContext = {id: this.object.id, modelId: this.object.modelId};
            usedTabs.push(panel.tab);
        });

        this.tabs = (config.tabs || []).filter(tab => usedTabs.includes(tab.id)).sort((a, b) => a.weight - b.weight);

        if (config.tabs.length) {
            const id = sessionStorage.getItem('sio_settings_tab');

            this.selectTab(config.tabs.find(tab => tab.id === id) || config.tabs[0]);
        }

        this.loading = false;
    }

    attached() {
        this.subscription = this.ea.subscribe('sio_form_post_submit', event => {
            if (event.config && this.object.modelId === event.config.modelId) {
                this.loading = true;
                this.object = this.client.get(this.object.modelId + '/' + this.object.id).then(object => {
                    this.object = object;
                    return this.bind();
                });
            }
        });
    }

    detached() {
        if (this.subscription) {
            this.subscription.dispose();
            this.subscription = null;
        }
    }

    selectTab(tab) {
        this.activeTab = tab;
        this.leftColumn = this.panels.filter(p => p.tab === tab.id && 'right' !== p.column);
        this.rightColumn = this.panels.filter(p => p.tab === tab.id && 'right' === p.column);
        sessionStorage.setItem('sio_settings_tab', tab.id);
    }
}

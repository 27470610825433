import React, {useState} from "react";
import TableViewer from "./table-viewer";
import GraphViewer from "./graph-viewer";
import {Button, ButtonGroup, ButtonToolbar, Dropdown, Icon, IconButton} from "rsuite";

import "./time-aggregation-viewer.less";
import {toLabel} from "./utils";

const VIEWS = Object.freeze([
    ["table", "grid", TableViewer],
    ["graph", "line-chart", GraphViewer]
]);

const DIFF = Object.freeze([
    [false, "ohne Vergleich"],
    ["period", "Vergleich mit vorherigem Zeitraum"],
    ["year", "Vergleich mit Vorjahr"],
]);

export const TimeAggregation = ({data, columns, grouping, labels, interval, excel}) => {

    if (!columns?.length) {
        return null;
    }

    const [view, switchView] = useState(VIEWS[0][0]);
    const [showSum, setShowSum] = useState(0 < grouping.length);
    const [diff, setDiff] = useState([false]);

    const Viewer = VIEWS.find(([name]) => view === name)[2];

    return (
        <div className="panel">
            <div className="panel-body" style={{padding: "10px"}}>

                <TimeAggregationToolbar
                    view={view}
                    switchView={switchView}
                    showSum={showSum}
                    setShowSum={setShowSum}
                    diff={diff}
                    setDiff={setDiff}
                    grouping={grouping}
                    excel={excel}
                />

                <Viewer
                    data={data}
                    columns={columns}
                    labels={labels}
                    grouping={grouping}
                    showSum={showSum}
                    interval={interval}
                    diff={diff}
                />

            </div>
        </div>
    )
}

const TimeAggregationToolbar = ({view, switchView, showSum, setShowSum, grouping, diff, setDiff, excel}) => {

    return (
        <>
            <div className="pull-right text-right">
                <ButtonGroup>
                    <IconButton title="Excel-Export" placement="left" onClick={() => excel(true)} icon={<SioIcon icon="excel"/>} />

                    <Button onClick={() => excel(false)}>
                        Roh-Daten Export
                    </Button>
                </ButtonGroup>
            </div>

            <ButtonToolbar>
                <ButtonGroup>
                    {VIEWS.map(([name, icon]) => (
                        <IconButton
                            key={name}
                            active={view === name}
                            onClick={() => switchView(name)}
                            icon={<SioIcon icon={icon}/>}
                        />
                    ))}
                </ButtonGroup>

                {0 < grouping.length && (
                    <>
                        <IconButton
                            active={showSum}
                            onClick={() => setShowSum(!showSum)}
                            title="Summen zeigen"
                            icon={<SioIcon icon="sum"/>}
                        />
                    </>
                )}

                {"table" === view && (
                    <>
                        <Dropdown title={DIFF.find(([d]) => d === diff[0])[1]}>
                            {DIFF.map(([key, label]) => (
                                <Dropdown.Item key={key}
                                               active={diff[0] === key}
                                               onClick={() => key ? setDiff([key, diff[1] ?? "diff-percentage"]) : setDiff([false])}>
                                    {label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown>

                        {diff[0] && (
                            <ButtonGroup>
                                <IconButton
                                    active={"diff-percentage" === diff[1]}
                                    icon={<SioIcon icon="percentage"/>}
                                    onClick={() => setDiff([diff[0], "diff-percentage"])}
                                />
                                <IconButton
                                    active={"diff" === diff[1]}
                                    icon={<SioIcon icon="plus-minus"/>}
                                    onClick={() => setDiff([diff[0], "diff"])}
                                />
                            </ButtonGroup>
                        )}
                    </>
                )}

            </ButtonToolbar>
        </>
    );
}

const SioIcon = ({icon}) => <Icon classPrefix="sio-icon" icon={icon}/>;

const makeDropdown = (data, grouping, labels, level) => Object.entries(data)
    .map(([group, subData]) => "__sums__" === group ? null :
        subData instanceof Array ? (
            <Dropdown.Item active={0 === level} key={group}>{toLabel(group, labels[grouping[level]])}</Dropdown.Item>
        ) : (
            <Dropdown.Menu key={group} active={0 === level} title={toLabel(group, labels[grouping[level]])}>
                {makeDropdown(subData, grouping, labels, level + 1)}
            </Dropdown.Menu>
        ));

export default TimeAggregation;

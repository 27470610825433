import {configureStore} from "@reduxjs/toolkit"
import {setupListeners} from "@reduxjs/toolkit/query"
import {api} from "./api"
import accounting from "../accounting/store/accounting-slice"
import calculation from "../tourism/journey-calculation/store/calculation-slice"
import costs from "../costs/store/costs-slice"

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        accounting,
        calculation,
        costs
    },
    middleware: middleware => middleware().concat(api.middleware)
})

export default store
setupListeners(store.dispatch)

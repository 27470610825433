import {useEffect} from "react"
import c3 from "c3"

export function Chart({config, ...props}) {
    useEffect(() => {
        const chart = c3.generate({
            bindto: "#c3-chart-container",
            ...config
        })

        return () => {
            chart.destroy()
        }
    })

    return (
        <div id="c3-chart-container" {...props}/>
    )
}

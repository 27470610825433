import numeral from "numeral";
import * as moment from "moment-timezone";

export class ValueFormatter {

    static format(formatter, value) {
        switch (formatter) {

            case 'money':
                return numeral(.01 * value).format('0,0.00') + ' €';

            case 'diff-money':
                return numeral(.01 * value).format('+0,0.00') + ' €';

            case 'number':
                return numeral(value).format('0,0.00');

            case 'diff':
                return numeral(value).format('+0,0.00');

            case 'percentage':
                return numeral(.01 * value).format('0.00%');

            case 'diff-percentage':
                return numeral(.01 * value).format('+0.0%');

            case 'days':
                const days = Math.floor(value);
                const hours = Math.floor(24 * (value - days));
                const minutes = Math.floor(1440 * (value - days - hours / 24));
                const seconds = Math.round(86400 * (value - days - hours / 24 - minutes / 1440));

                return (0 < days ? days + 'd ' : '') + [hours, minutes, seconds].map(x => (10 > x ? '0' : '') + x).join(':');

            default:
                return numeral(value).format('0,0');
        }
    }
}

export default ValueFormatter;

import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import {Container} from "aurelia-dependency-injection"
import {AureliaConfiguration} from "aurelia-configuration"
import {FlashService} from "../flash/flash-service"
import {AuthTokenStorage} from "../auth/auth-token-storage"

/**
 * @typedef {{ id: string, modelId: string }} ObjectRef
 * @typedef {{ amount: int, currency: string }} Money
 */

/** @type FlashService */
const flash = Container.instance.get(FlashService)
const baseUrl = Container.instance.get(AureliaConfiguration).get('apiUrl')

const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders(headers) {
        // @todo refresh token
        headers.set("authorization", "Bearer " + Container.instance.get(AuthTokenStorage).getAccessToken())
        return headers
    }
})

/** @type {(successMessage?: string) => (_: any, {queryFulfilled: Promise}) => Promise} */
export const onQueryStarted = successMessage => (_, {queryFulfilled}) => queryFulfilled.then(() => {
    flash.success(successMessage ?? "Erfolgreich gespeichert")
}, error => {
    console.error(error)
    flash.error("Fehler beim Speichern.")
})

export const api = createApi({
    reducerPath: "api",
    baseQuery,
    tagTypes: [
        "accounting/booking",
        "tourism-journey-calculation/calculation",
        "costs/real-costs-origin"
    ],
    endpoints: build => ({
        organization: build.query({
            query: id => "organization/organization/" + id,
        }),

        getCalculations: build.query({
            query: itinerary => ({
                url: "tourism-journey-calculation/calculation",
                params: {
                    conditions: JSON.stringify({itinerary}),
                    "sort[0][0]": "fromDate",
                    "sort[0][1]": "ASC"
                }
            }),
            transformResponse: ({items = []}) => items,
            providesTags: () => ["tourism-journey-calculation/calculation"]
        }),

        calculationDetails: build.query({
            query: body => ({
                url: "tourism-journey-calculation/query/backend",
                method: "POST",
                body
            }),
            transformResponse(data) {
                const groupKeys = []
                const columnKeys = []

                for (const [groupId, {from, to, columns}] of Object.entries(data?.columns ?? {})) {
                    const keys = Object.keys(columns)
                    groupKeys.push({
                        groupId,
                        from: from.substring(0, 10),
                        to: to.substring(0, 10),
                        colSpan: keys.length
                    })

                    for (const [paxId, [from, to]] of Object.entries(columns)) {
                        columnKeys.push({
                            groupId, paxId, from, to,
                            key: groupId + '|' + paxId,
                            border: paxId === keys[keys.length - 1]
                        })
                    }
                }

                return {...data, groupKeys, columnKeys}
            }
        }),

        calculationSettings: build.query({
            query: id => "organization/organization/" + id,
            transformResponse({settings: {tourismJourneyCalculationSettings: settings}}) {
            }
        }),

        calculationServices: build.query({
            query: () => "form/choice/tourism_journey_calculation_service",
        }),

        calculationSave: build.mutation({
            query: ({id, ...body}) => ({
                url: "tourism-journey-calculation/calculation-edit/" + id,
                method: "PUT",
                body
            }),
            onQueryStarted: onQueryStarted(),
            invalidatesTags: ["tourism-journey-calculation/calculation"]
        }),

        swiftInfo: build.query({
            query: swift => "base/swift-info/" + swift
        }),

        interfaceImplementations: build.query({
            query: interfaceId => ({url: `interface/implementations/${interfaceId}`})
        }),

        costsRealCostsOrigin: build.query({
            query: ({origin, currency, prices = []}) => ({
                url: "costs/query/real-costs/origin",
                method: "POST",
                body: {origin, currency, prices}
            }),
            providesTags: [{type: "costs/real-costs-origin"}]
        }),

        costsRealCostsOriginSave: build.mutation({
            query: body => ({
                url: "costs/command/real-costs/set-prices",
                method: "POST",
                body
            }),
            onQueryStarted: onQueryStarted(),
            invalidatesTags: [{type: "costs/real-costs-origin"}],
        }),

        costsRealCostsOriginDeleteAssignment: build.mutation({
            query: body => ({
                url: "costs/command/real-costs/delete-assignment",
                method: "POST",
                body
            }),
            onQueryStarted: onQueryStarted("Erfolgreich gelöscht"),
            invalidatesTags: [{type: "costs/real-costs-origin"}],
        }),

        costsRealCostsOriginAddAssignments: build.mutation({
            query: body => ({
                url: "costs/command/real-costs/add-assignments",
                method: "POST",
                body
            }),
            onQueryStarted: onQueryStarted(),
            invalidatesTags: [{type: "costs/real-costs-origin"}],
        })
    })
})

export const {
    useOrganizationQuery,
    useGetCalculationsQuery,
    useCalculationDetailsQuery,
    useCalculationServicesQuery,
    useCalculationSaveMutation,
    useInterfaceImplementationsQuery,
    useCostsRealCostsOriginQuery,
    useCostsRealCostsOriginSaveMutation,
    useCostsRealCostsOriginDeleteAssignmentMutation,
    useCostsRealCostsOriginAddAssignmentsMutation,
    endpoints: {
        calculationDetails: {
            matchPending: calculationDetailsLoading,
            matchFulfilled: calculationDetailsLoaded
        },
        calculationSave: {
            matchFulfilled: calculationSaved
        },
        swiftInfo,
        costsRealCostsOrigin: {
            matchPending: costsRealCostsOriginLoading,
            matchFulfilled: costsRealCostsOriginLoaded
        },
        costsRealCostsOriginDeleteAssignment: {
            matchFulfilled: costsRealCostsOriginAssignmentDeleted
        }
    }
} = api
